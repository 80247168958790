import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './scss/style.scss';
import 'react-notifications/lib/notifications.css';
import {NotificationContainer} from 'react-notifications';


const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const VerifyAccount = React.lazy(() => import('./views/pages/verifyAccount/verifyAccount'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
const ManageCompanyPage = React.lazy(() => import('./views/pages/manageCompany/manage_company'));
// const Charts = React.lazy(()=>import('./views/charts/Charts'))
const GraphsPage = React.lazy(()=> import('./Graphs'));
const chartsPage= React.lazy(()=> import('./views/charts/Charts'))


class App extends Component {

  render() {
    return (
      
        <BrowserRouter>
            <React.Suspense fallback={loading}>
              <Switch>
                <Route exact path="/" name="Login Page" render={props => <Login {...props}/>} />
                <Route exact path="/verify_account/:email" name="Verify Account" render={props => <VerifyAccount {...props}/>} />
                <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
                <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
                <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
                <Route path="/" name="Login page" render={props => <TheLayout {...props}/>} />
              </Switch>
              <NotificationContainer/>
            </React.Suspense>
           
        </BrowserRouter>
    );
  }
}

export default App;
